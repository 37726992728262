/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@1.12.4/dist/jquery.min.js
 * - /npm/jquery-migrate@1.2.1/dist/jquery-migrate.min.js
 * - /npm/owl.carousel@2.3.4/dist/owl.carousel.min.js
 * - /npm/jquery.scrollto@2.1.2/jquery.scrollTo.min.js
 * - /npm/jquery.easing@1.3.0/jquery.easing.1.3.min.js
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js
 * - /npm/jquery.serialscroll@1.3.0/jquery.serialScroll.min.js
 * - /npm/bxslider@4.2.6/dist/jquery.bxslider.min.js
 * - /npm/jcarousel@0.3.1/dist/jquery.jcarousel.min.js
 * - /npm/jquery-form-validator@2.3.54/form-validator/jquery.form-validator.min.js
 * - /npm/bootstrap@3.3.2/dist/js/bootstrap.min.js
 * - /npm/bootstrap-3-typeahead@4.0.0/bootstrap3-typeahead.min.js
 * - /npm/jquery.typewatch@2.1.0/jquery.typewatch.min.js
 * - /npm/selectize@0.12.6/dist/js/standalone/selectize.min.js
 * - /npm/fractionslider@1.0.0/jquery.fractionslider.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
